import React, {useState} from 'react';
import { Container, Typography, Box, Paper, TextField, Button, Snackbar, Alert } from '@mui/material';
import dataService from '../services/dataService';

function AddRestaurantScreen() {
    const [restaurantName, setRestaurantName] = useState('');
    const [menus, setMenus] = useState([{menu_data: ''}])
    const [documents, setDocuments] = useState([{document_type: 'Ingredients', document_data: ''}])
    const [loadingStatus, setLoadingStatus] = useState(null);

    const save = async function() {
      console.log(restaurantName, menus, documents)
      await setLoadingStatus('loading');
      try {
        let resp = await dataService.createRestaurant({restaurant: {name: restaurantName}, menus, documents})
        console.log(resp);
        setLoadingStatus('Saved!');
      } catch (error) {
        console.error(error)
        setLoadingStatus('error: ' + error);
      }
    }

    return (
        <Container maxWidth="sm">
            <Box my={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Restaurant Data
                    </Typography>
                    <TextField
                      label="Restaurant Name"
                      fullWidth
                      value={restaurantName}
                      onChange={(e) => setRestaurantName(e.target.value)}
                    />
                </Paper>

                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Menus
                    </Typography>
                    {menus.map((menu , menuIdx) => (
                      <Paper elevation={4} sx={{ p: 2, mt: 2, width: '100%' }}>
                        <TextField
                          label='Menu Text'
                          fullWidth
                          value={menu.menu_data}
                          multiline
                          onChange={(e) => {
                            let newMenus = menus.map((item, idx) => {
                                if (idx === menuIdx) {
                                    return { ...item, menu_data: e.target.value };
                                }
                                return item;
                            });
                            setMenus(newMenus);
                          }}
                        />
                        <Button fullWidth onClick={() => setMenus((menus) => menus.filter((menuValue, idx) => idx !== menuIdx))}>Delete Menu</Button>
                      </Paper>
                    ))}
                    <Paper elevation={4} sx={{ p: 2, mt: 2, width: '100%' }}>
                      <Button variant='contained' fullWidth onClick={() => setMenus((menus) => [...menus, {menu_data: ''}])}>Add Menu</Button>
                    </Paper>
                </Paper>

                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Documents
                    </Typography>
                    {documents.map((document , documentIdx) => (
                      <Paper elevation={4} sx={{ p: 2, mt: 2, width: '100%' }}>
                        <TextField
                          label='Document Type'
                          fullWidth
                          value={document.document_type}
                          onChange={(e) => {
                            let newDocuments = documents.map((item, idx) => {
                                if (idx === documentIdx) {
                                    return { ...item, document_type: e.target.value };
                                }
                                return item;
                            });
                            setDocuments(newDocuments);
                          }}
                        />
                        <TextField
                          label='Document Data'
                          fullWidth
                          value={document.document_data}
                          multiline
                          onChange={(e) => {
                            let newDocuments = documents.map((item, idx) => {
                                if (idx === documentIdx) {
                                    return { ...item, document_data: e.target.value };
                                }
                                return item;
                            });
                            setDocuments(newDocuments);
                          }}
                        />
                        <Button fullWidth onClick={() => setDocuments((documents) => documents.filter((docValue, idx) => idx !== documentIdx))}>Delete Document</Button>
                      </Paper>
                    ))}
                    <Paper elevation={4} sx={{ p: 2, mt: 2, width: '100%' }}>
                      <Button variant='contained' fullWidth onClick={() => setDocuments((documents) => [...documents, {document_data: ''}])}>Add Document</Button>
                    </Paper>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                  <Button variant='contained' fullWidth onClick={save}>Save</Button>
                </Paper>
            </Box>
            <Snackbar
              open={loadingStatus !== null}
              autoHideDuration={6000}
              onClose={() => setLoadingStatus(null)}
              anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              <Alert severity={loadingStatus === 'Saved!' ? 'success' : 'info'} sx={{ width: '100%' }}>
                {loadingStatus}
              </Alert>
          </Snackbar>
        </Container>
    );
}

export default AddRestaurantScreen;


