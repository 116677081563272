let BASE_URL;

if (process.env.NODE_ENV === 'development') {
  BASE_URL = 'http://localhost:5000/api';
}  else {
  //BASE_URL = '/api';
  BASE_URL = 'https://chatwithmenu.com/api'
}

export {BASE_URL};
