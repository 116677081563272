import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/authService';
import { UserContext } from '../UserContext';

import {Button, Paper, Grid, Container, TextField} from '@mui/material';


const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);

  const handleLogin = async () => {
    try {
      const result = await authService.login(email, password);
      console.log(result);
      if (result.id !== null) {
        localStorage.setItem('userId', `${result.id}`);
        setUserId(result.id);
        navigate('/dashboard');
      } else {
        alert("Login Failed: " + result.message);
      }
    } catch (error) {
      alert(`Error: An error occurred during login: ${error}`);
    }
  };

  useEffect(() => {
    const loadUserId = async () => {
      try {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId !== null) {
          console.log('going to dashboard');
          setUserId(storedUserId);
          navigate('/dashboard');
        }
      } catch (error) {
        console.log('not going to dashboard', error);
      }
    };
    loadUserId();
  }, [navigate, setUserId]);

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </Grid>
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth
              onClick={handleLogin}
            >
              Login
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button 
              variant="outlined" 
              color="secondary" 
              fullWidth
              onClick={() => navigate('/create-account')}
            >
              Go to Create Account
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default LoginScreen;
