import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

function AboutScreen() {
    return (
        <Container maxWidth="sm">
            <Box my={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h3" component="h1" gutterBottom>
                    Chat With Menu
                </Typography>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="body1">
                        Chat With Menu is an AI-powered chatbot that helps people with dietary needs find suitable menu options when dining out. Simply scan a QR code on the menu to access the chatbot. Enter any dietary restrictions, and it will suggest personalized recommendations after cross-referencing the ingredients. 
                    </Typography>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="body1">
                        The chatbot eliminates reliance on servers to remember every ingredient and prep method. It also provides allergen and nutrition information in-app. This improves safety for people with food allergies or intolerances. 
                    </Typography>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="body1">
                        For restaurants, Chat With Menu seamlessly integrates as an affordable add-on to existing tech stacks. It reduces workload for staff while enhancing guest satisfaction. The chatbot is accessible on web, mobile, and in multiple languages. 
                    </Typography>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="body1">
                        Our founding team combines expertise in AI, restaurant operations, nutrition, and marketing. We share a passion for leveraging technology to remove dining limitations for millions of underserved customers. Chat With Menu is just the start of the inclusive solutions we aim to build. 
                    </Typography>
                </Paper>
                <Paper elevation={3} sx={{ p: 2, mt: 2, width: '100%' }}>
                    <Typography variant="body1">
                        The business model focuses on recurring SaaS subscription revenue from restaurants. There are also transaction fees based on customer usage. We provide customized enterprise solutions for large chains as well. The chatbot will ultimately pay for itself by driving guest loyalty and engagement.
                    </Typography>
                </Paper>
            </Box>
        </Container>
    );
}

export default AboutScreen;


