import React from 'react';
import { Container, Box, Typography, Button, AppBar, Toolbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function LandingScreen() {
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1, backgroundImage: 'url(/landing.jpeg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundImage: 'url(background.jpg)', backgroundSize: 'cover' }}>
        <Typography variant="h4" gutterBottom component="div" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'center' }}>
          Never stress about what & where to eat ever again
        </Typography>
        <Button variant="contained" sx={{ mt: 3, bgcolor: 'secondary.main' }} onClick={() => navigate("/login")}>
          Sign In
        </Button>
      </Container>
    </Box>
  );
}

export default LandingScreen;
