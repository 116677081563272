import { BASE_URL } from "./backendData";

const loadUserData = async (userId) => {
  const response = await fetch(`${BASE_URL}/loadUser/${userId}`);
  const userData = await response.json();
  return userData;
};

const modifyUserData = async (userId, user_data) => {
  const response = await fetch(`${BASE_URL}/modifyUser/${userId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_data }),
  });
  return await response.json();
};


const listRestaurants = async () => {
  const response = await fetch(`${BASE_URL}/listRestaurants`);
  const restaurantData = await response.json();
  return restaurantData;
};

const startChat = async (user_id, restaurant_id, force_new) => {

  user_id = parseInt(user_id);
  restaurant_id = parseInt(restaurant_id);

  const response = await fetch(`${BASE_URL}/startChat`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_id, restaurant_id, force_new }),
  });
  return await response.json();
}

const sendMessage = async (chat_id, message) => {
  const response = await fetch(`${BASE_URL}/sendMessage`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ chat_id, message })
  });
  return await response.json();
}

const submitReview = async (user_id, restaurant_id, chat_id, item, rating, review_text) => {
  const response = await fetch(`${BASE_URL}/submitReview`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user_id, restaurant_id, chat_id, item, rating, review_text })
  });
  return await response.json();
}

const loadUserPreferences = async (user_id, restrictionType) => {
  const response = await fetch(`${BASE_URL}/loadUserPreferences/${user_id}/${restrictionType}`);
  const data = await response.json();
  return data;
}

const saveUserPreferences = async (user_id, dietary_restrictions, restrictionType) => {
  const response = await fetch(`${BASE_URL}/saveUserPreferences/${user_id}/${restrictionType}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ dietary_restrictions })
  });
  const data = await response.json();
  return data;
}

const saveUserPrefrencesAudio = async (user_id, audioBlob) => {
  const formData = new FormData();
  console.log("Received audio blob", audioBlob);
  formData.append('audio_file', audioBlob)
  console.log("formData", formData)
  const response = await fetch(`${BASE_URL}/saveUserPreferencesAudio/${user_id}`, {
    method: 'POST',
    body: formData,
  });
  const data = await response.json();
  return data;
}

const listUserRestaurantReviews = async (user_id, restaurant_id) => {
  const response = await fetch(`${BASE_URL}/reviews/${user_id}/${restaurant_id}`)
  const data = await response.json();
  return data; 
}

const editUserRestaurantReviews = async (user_id, restaurant_id, reviews) => {
  const response = await fetch(`${BASE_URL}/reviews/${user_id}/${restaurant_id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reviews })
  });
  const data = await response.json(); 
  return data;
}

const genImageDish = async(restaurantId, dishName) => {
  const response = await fetch(`${BASE_URL}/imagegen/dish/${restaurantId}/${dishName}`)
  const data = await response.json();
  return data;
}

const menuItemsFromChat = async(chatId) => {
  const response = await fetch(`${BASE_URL}/chat/${chatId}/menu_items`)
  const data = await response.json();
  return data;
}

const createRestaurant = async(restaurantData) => {
  const response = await fetch(`${BASE_URL}/restaurant`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(restaurantData)
  });
  const data = await response.json(); 
  return data;
}

const deleteRestaurant = async (userId, restaurantId) => {
  const response = await fetch(`${BASE_URL}/restaurant/${restaurantId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({user_id: userId})
  });
  const data = await response.json(); 
  return data;
}

export default {
  loadUserData,
  modifyUserData,
  listRestaurants,
  startChat,
  sendMessage,
  loadUserPreferences,
  saveUserPreferences,
  submitReview,
  saveUserPrefrencesAudio,
  listUserRestaurantReviews,
  editUserRestaurantReviews, 
  genImageDish,
  menuItemsFromChat,
  createRestaurant,
  deleteRestaurant,
};
