import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Container, Box, AppBar, Toolbar, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';


const NavBar = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => navigate("/")}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/dashboard" style={styles.link}>
              Chat With Menu
            </Link>
          </Typography>
          <Button color="inherit"><Link to="/about" style={styles.link}>About</Link></Button>
          <Button color="inherit"><Link to="/how-it-works" style={styles.link}>Features</Link></Button>
          <Button color="inherit"><Link to="/pricing" style={styles.link}>Pricing</Link></Button>
          {localStorage && localStorage.length > 0 ? (<Button color="inherit" onClick={logout}>Logout</Button>) : null}
        </Toolbar>
      </AppBar>
  );
};

const styles = {
  navBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#333',
    color: 'white',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    marginRight: '20px',
  },
};

const Layout = ({ children }) => {
    return (
      <div>
        <NavBar/>
        <main>{children}</main>
      </div>
    );
  };
  
  export default Layout;
