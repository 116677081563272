// App.js
import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Container,
  Avatar,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HowItWorksScreen = () => {
  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={5}
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          How It Works!
        </Typography>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Avatar sx={{ bgcolor: "#0077B6", width: 56, height: 56 }}>
                <RestaurantIcon fontSize="large" />
              </Avatar>
              <Typography variant="body1" mt={2}>
                Pick a restaurant online or scan the QR Code on the menu
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Avatar sx={{ bgcolor: "#0077B6", width: 56, height: 56 }}>
                <ChatIcon fontSize="large" />
              </Avatar>
              <Typography variant="body1" mt={2}>
                Inform our Chat bot of any restrictions you may have
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
            >
              <Avatar sx={{ bgcolor: "#0077B6", width: 56, height: 56 }}>
                <CheckCircleIcon fontSize="large" />
              </Avatar>
              <Typography variant="body1" mt={2}>
                Get a recommendation in under 60 seconds and order away
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={5} mb={5}>
        <Typography variant="h5" gutterBottom>
          Discover
        </Typography>
        <Grid container spacing={3}>
          {[
            { city: "New York", image: "howitworks/nyc.webp" },
            { city: "San Francisco", image: "howitworks/sfo.webp" },
            { city: "Chicago", image: "howitworks/chi.webp" },
          ].map(({ city, image }) => (
            <Grid item xs={12} sm={4} key={city}>
              <Card>
                <CardMedia
                  component="img"
                  alt={city}
                  height="140"
                  image={image}
                />
                <CardContent>
                  <Typography variant="h6">{city}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box mt={5} mb={5}>
        <Typography variant="h5" gutterBottom>
          Popular Restaurants Near You
        </Typography>
        <Grid container spacing={3}>
          {[
            { name: "Il Violino", image: "howitworks/rest_ilv.webp" },
            { name: "Chipotle", image: "howitworks/rest_chip.webp" },
            { name: "Carrot Express", image: "howitworks/rest_carr.webp" },
          ].map(({ name, image }) => (
            <Grid item xs={12} sm={4} key={name}>
              <Card>
                <CardMedia
                  component="img"
                  alt={name}
                  height="140"
                  image={image}
                />
                <CardContent>
                  <Typography variant="h6">{name}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default HowItWorksScreen;
