import React, { useState } from 'react';
import { Button, TextField, Card, CardContent, Grid, Box } from '@mui/material';

function InputArea({ onSend, messageHints }) {
  const [input, setInput] = useState('');

  const handleSend = () => {
    onSend(input);
    setInput('');
  };

  return (
    <Card variant='outlined'>
      <CardContent>
        <Box mb={2}>
          <Grid container spacing={1}>
            {messageHints.map((msg, index) => (
              <Grid item xs={12} sm={12} md={12} key={index}>
                <Button variant='contained' onClick={() => onSend(msg)} fullWidth>
                  {msg}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Card variant='outlined'>
          <CardContent>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={9}>
                <TextField
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  placeholder="Type a message..."
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <Button variant='contained' onClick={handleSend} fullWidth>Send</Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
}

export default InputArea;
