import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { UserContext } from '../UserContext';
import dataService from '../services/dataService';
import InputArea from '../components/Chat/InputArea';
import TopMenuBar from '../components/Chat/TopMenuBar';
import { getRandText } from '../randomText';
import AddReviewModal from '../components/Chat/AddReviewModal';
import EditReviewsModal from '../components/Chat/EditReviewsModal';
import DishViewer from '../components/Chat/DishViewer';

import { List, Avatar, LinearProgress, Card, CardContent, Box, Typography } from '@mui/material';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { Snackbar, Alert } from '@mui/material';
import Markdown from 'react-markdown'; // React Markdown library for web


function ChatScreen() {
  const bottomListRef = useRef(null);

  const location = useLocation();
  const { userId } = useContext(UserContext);
  const restaurantId = location.state?.restaurantId;
  const restaurantName = location.state?.restaurantName;
  const [messageHints, setMessageHints] = useState([
    "Tell me what I'd like on the menu" + (userId == "0" ? ` in the style of ${getRandText()}` : "") + ". 😁",
    'What is a conservative option for me? 😊',
    'Give me a wild, interesting menu choice I would not normally pick! 😜'
    // TODO: Last time, what did i like (only when available)
  ]);
  const [messages, setMessages] = useState([]);
  const [chatId, setChatId] = useState(-1);
  const [showAddReview, setShowAddReview] = useState(false);
  const [showEditReviews, setShowEditReviews] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState(null);
  const [alert, setAlert] = useState(null);
  const [menuItemsFromChat, setMenuItemsFromChat] = useState([]);

  useEffect(() => {
    bottomListRef.current?.scrollIntoView({ behavior: "smooth" });
    const fetchItems = async () => {
      if (messages.length <= 4) return
      try {
        let data = await dataService.menuItemsFromChat(chatId);
        setMenuItemsFromChat(data['items'])
      } catch (error) {
        console.log(error)
      }
    }
    fetchItems()
  }, [messages]);

  useEffect(() => {
    // Fetch existing chat messages from the server
    const fetchChatData = async () => {
      try {
        const chatData = await dataService.startChat(userId, restaurantId);
        setChatId(chatData.id)
        setMessages(JSON.parse(chatData.conversation_data));
      } catch (error) {
        console.log(error);
      }
    };
    
    fetchChatData()
  }, [userId, restaurantId]);

  const sendMessage = async (inputMsg) => {
    setMessages((msgs) => [...msgs, { role: 'user', content: inputMsg }]);
    setLoading(true)
    const fetchChatData = async (text) => {
      try {
        const chatData = await dataService.sendMessage(chatId, text);
        console.log("Got response", chatData.response)
        setLoading(false) 
        setMessages((msgs) => [...msgs, chatData.response]);
      } catch (error) {
        console.log(error);
      }
    }
    fetchChatData(inputMsg)
    setMessageHints((hints) => ["Tell me what I'd like on the menu" + (userId == "0" ? ` in the style of ${getRandText()}` : "") + ". 😁", ...hints.slice(1)])
  };

  const newChat = async() => {
    if (restaurantId === undefined || restaurantId === null) {
      console.log("Restaurant id is ", restaurantId, " giving up.")
      return;
    }
    try {
      const chatData = await dataService.startChat(userId, restaurantId, true);
      setChatId(chatData.id)
      setMessages(JSON.parse(chatData.conversation_data));
      setMenuItemsFromChat([]);
    } catch (error) {
      console.log(error);
    }
  }

  const addRating = async({menuItem, rating, extraText}) => {
    try {
      const resp = await dataService.submitReview(userId, restaurantId, chatId, menuItem, rating, extraText)
      setShowAddReview(false)
      setAlert("Review saved!")
      console.log(resp)
    } catch(error) {
      alert(error);
    }
  }

  const modifyReviews = async(reviews) => {
    await dataService.editUserRestaurantReviews(userId, restaurantId, reviews)
    setShowEditReviews(false)
    setAlert('Saved successfully!')
  }

  const loadAndShowReviews = async() => {
    // Ask for reviews
    let reviewData = await dataService.listUserRestaurantReviews(userId, restaurantId);
    await setReviews(reviewData)
    // TODO: Show loading until they are there
    // Then show reviews
    setShowEditReviews(true);
  }

  return (
    <>
      <TopMenuBar
        restaurantName={restaurantName}
        onNewChat={newChat}
        onAddReview={() => setShowAddReview(true)}
        onEditReviews={loadAndShowReviews}
      />
      <AddReviewModal open={showAddReview} onClose={() => setShowAddReview(false)} onSubmit={addRating} suggested={menuItemsFromChat} />
      <EditReviewsModal open={showEditReviews} onClose={() => setShowEditReviews(false)} onSubmit={modifyReviews} reviews={reviews} />
      <Snackbar
        open={alert !== null}
        autoHideDuration={6000}
        onClose={() => setAlert(null)}
        anchorOrigin={{horizontal: 'center', vertical: 'top'}}
      >
        <Alert severity='success' sx={{ width: '100%' }}>{alert}</Alert>
      </Snackbar>
      <List>
        {messages ? (messages.map((message, index) => {
          if (message.role === 'system') return null;
          let bgColor = '#1976d2'; // blue
          if (message.role !== 'user') bgColor = 'gray';
          return (
            <Card key={index} variant="outlined" sx={{ mb: 2, display: 'flex', flexDirection: 'column', borderRadius: '10px', border: `2px solid ${bgColor}`, backgroundColor: bgColor, textAlign: (message.role === 'user' ? 'right' : 'left') }}>
              <CardContent sx={{ display: 'flex', flexDirection: message.role === 'user' ? 'row-reverse' : 'row', alignItems: 'center' }}>
                <Avatar sx={{ mx: 2 }}>
                  {message.role === 'user' ? <SentimentSatisfiedAltIcon /> : <RestaurantIcon />}
                </Avatar>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="body2" color='white'>
                    <Markdown>{message.content}</Markdown>
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          );
        })) : null}
        <div ref={bottomListRef} />
      </List>
      <DishViewer restaurantId={restaurantId} dishes={menuItemsFromChat} />
      {loading && <LinearProgress />}
      <InputArea messageHints={messageHints} onSend={sendMessage} />
    </>
  );
}

export default ChatScreen;
