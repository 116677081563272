import React, { useState, useEffect, useContext } from 'react';
import dataService from '../services/dataService';
import { FOODS, PREPS } from '../randomText';
import FeedbackScale from '../components/FeedbackScale';
import AudioRecorderModal from "../components/AudioRecorderModal";
import { UserContext } from '../UserContext';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { Snackbar, Alert, Grid } from '@mui/material';


const ModifyPreferencesScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location', location)
  const restrictionType = location.state?.restrictionType;
  const { userId } = useContext(UserContext);
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [audioRecordingState, setAudioRecordingState] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const foodPrefs = await dataService.loadUserPreferences(userId, restrictionType);
        setDietaryRestrictions(foodPrefs);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPreferences();
  }, [userId, restrictionType]);

  const handleInputChange = (index, field, value) => {
    const updatedRestrictions = dietaryRestrictions.map((item, i) => {
      if (index === i) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setDietaryRestrictions(updatedRestrictions);
  };

  const saveChanges = async () => {
    setSaveStatus('Saving...')
    const savePreferences = async () => {
      try {
        await dataService.saveUserPreferences(userId, dietaryRestrictions, restrictionType);
        setSaveStatus('Saved!')
        navigate('/dashboard')
      } catch (error) {
        console.log(error);
        setSaveStatus('Error!')
      }
    };
    
    savePreferences()
  }

  const addPref = () => {
    setDietaryRestrictions((prefs) => [...prefs, {
      ingredient: FOODS[Math.floor(Math.random()*FOODS.length)],
      level: Math.random()*2 - 1}])
  }

  const deleteDietaryRestriction = (index) => {
    const updatedRestrictions = dietaryRestrictions.filter((_, i) => i !== index);
    setDietaryRestrictions(updatedRestrictions);
  };

  const startRecording = function() {
    console.log("Got here");
    // Change state to recording
    setAudioRecordingState('recording');
  }

  const saveRecording = async(audio) => {
    // When recording is done, send recording
    console.log("audio", audio);
    // Receive data from backend and display
    setAudioRecordingState("loading")
    try{
      let data = await dataService.saveUserPrefrencesAudio(userId, audio, restrictionType);
      console.log("got back audio after saving", data)
      setDietaryRestrictions(data);
      setAudioRecordingState("complete")
    } catch(error) {
      console.error('Error sending data',error);
      setAudioRecordingState("error")
    }
  }

  let audioRecordingDisplay = null;
  if (restrictionType !== 'allergy' && audioRecordingState === "recording") {
    audioRecordingDisplay = (<AudioRecorderModal onStop={saveRecording} />)
  } else if (audioRecordingState === "loading") {
    audioRecordingDisplay = (<Paper><LinearProgress/><Typography variant="h6">Loading...</Typography></Paper>)
  } else if (audioRecordingState === "complete") {
    audioRecordingDisplay = (<Paper><Typography variant="h6">Complete!</Typography></Paper>)
  } else if (audioRecordingState === "error") {
    audioRecordingDisplay = (<Paper><Typography variant="h6">ERROR</Typography></Paper>)
  }

  return (
    <Paper style={{ padding: '20px', margin: '10px' }}>
      <Grid container spacing={2} direction="column">
        {restrictionType !== 'allergy' && (
          <Grid item>
            <Button fullWidth variant="contained" onClick={startRecording}>
              Record Preferences
            </Button>
          </Grid>
        )}

        {audioRecordingDisplay && (
          <Grid item>
            {audioRecordingDisplay}
          </Grid>
        )}

        {dietaryRestrictions.map((item, index) => (
          <Grid item key={index}>
            <Card variant="outlined">
              <Paper style={{ padding: '10px' }}>
                <Typography variant="h6">Ingredient:</Typography>
                <TextField
                  fullWidth
                  value={item.ingredient}
                  onChange={(e) => handleInputChange(index, 'ingredient', e.target.value)}
                />
              </Paper>
              {restrictionType !== 'allergy' && (
                <FeedbackScale
                  scaleText="Preference Level"
                  selectedNumber={Math.min(Math.max(1, Math.round((item.level + 1) * 5)), 10)}
                  setSelectedNumber={(value) => handleInputChange(index, 'level', (value / 5) - 1)}
                />
              )}
              <Button fullWidth onClick={() => deleteDietaryRestriction(index)}>
                Delete
              </Button>
            </Card>
          </Grid>
        ))}

        <Grid item>
          <Button fullWidth variant="outlined" onClick={addPref}>
            Add {restrictionType[0].toUpperCase()}{restrictionType.substring(1)}
          </Button>
        </Grid>

        <Grid item>
          {saveStatus === 'Saving...' ? (
            <>
              <LinearProgress />
              <Button fullWidth disabled variant="outlined">
                Save Changes
              </Button>
            </>
          ) : (
            <Button fullWidth variant="outlined" onClick={saveChanges}>
              Save Changes
            </Button>
          )}
        </Grid>

        <Grid item>
          <Snackbar
            open={saveStatus !== null}
            autoHideDuration={6000}
            onClose={() => setSaveStatus(null)}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert severity={saveStatus === 'Saved!' ? 'success' : 'info'} sx={{ width: '100%' }}>
              {saveStatus}
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ModifyPreferencesScreen;
