import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, CircularProgress, Select, MenuItem } from '@mui/material';
import dataService from '../services/dataService';
import { useLocation, useNavigate } from 'react-router-dom';


const EditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId;
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    bio: '',
    account_type: 0,
  });

  useEffect(() => {
    const loadUser = async () => {
        try {
            let data = await dataService.loadUserData(userId)
            console.log(data)
            setProfile({name: data.name, email: data.email, bio: data.bio, account_type: data.account_type})
            setLoading(false)
        } catch(error) {
            console.log(error)
        }
    }
    if (userId !== undefined) loadUser();
  }, [userId])

  const handleChange = (e) => {
    console.log(e.target)
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the submission logic here
    console.log(profile);
    const modifyUser = async () => {
      try {
        let newUser = await dataService.modifyUserData(userId, profile);
        console.log(newUser);
        navigate('/dashboard')
      } catch(error) {
        console.log(error)
      }
    }
    setLoading(true)
    modifyUser()
  };

  if (loading) return (<CircularProgress />)

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5">
        Edit Profile
      </Typography>
      <form onSubmit={handleSubmit}>
        <Select
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="account_type"
          label="Account Type"
          name="account_type"
          autoFocus
          value={profile.account_type}
          onChange={handleChange}
        >
          <MenuItem value={0}>Normal User</MenuItem>
          <MenuItem value={2}>Merchant</MenuItem>
          <MenuItem value={3}>Waiter</MenuItem>
        </Select>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoFocus
          value={profile.name}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          value={profile.email}
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="bio"
          label="Bio"
          id="bio"
          multiline
          rows={4}
          value={profile.bio}
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Save Changes
        </Button>
      </form>
    </Container>
  );
};

export default EditProfile;
