import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
  useTheme
} from "@mui/material";

const PricingScreen = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        backgroundColor: "#d9e9f5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(100vh - 64px)", // 64px is the App Bar height
      }}
    >
      <Card sx={{ maxWidth: 500, width: "100%", padding: isMobile ? "16px" : "32px" }}>
        <CardContent>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginBottom: "16px" }}
          >
            ALWAYS FREE FOR CUSTOMERS
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", marginBottom: "16px", fontWeight: "bold" }}
          >
            Pricing for restaurants
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="h3"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                39.99
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                Per Month
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <ul style={{ listStyle: "none", padding: 0 }}>
                <li>
                  <Typography variant="body1">• Digital Menu</Typography>
                </li>
                <li>
                  <Typography variant="body1">• Allergen Awareness</Typography>
                </li>
                <li>
                  <Typography variant="body1">• Chat bot Management</Typography>
                </li>
                <li>
                  <Typography variant="body1">• Customer Support</Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "center", marginTop: "24px" }}>
            <Typography variant="h6">Interested in a demo?</Typography>
            <Button
              variant="contained"
              sx={{
                marginTop: "8px",
                backgroundColor: "#f0ece5",
                color: "#000",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
              onClick={() => window.open('https://calendly.com/chatwithmenu', '_blank')}
            >
              Setup a meeting now
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PricingScreen;
