import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import dataService from '../services/dataService';
import { UserContext } from '../UserContext';

import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Typography, Paper, Grid, Container, IconButton, Box } from '@mui/material';


const canUserEditRestaurants = (userData) => {
  return (userData?.account_type === 1 || userData?.account_type === 2);
}


const DashboardScreen = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [restaurants, setRestaurants] = useState([]);

  useEffect(() => {
    if (userId === null || userId === undefined) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const data = await dataService.loadUserData(userId);
        setUserData(data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRestaurants = async () => {
      try {
        const data = await dataService.listRestaurants();
        setRestaurants(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData();
    fetchRestaurants();
  }, [userId, navigate]);

  const logout = async () => {
    localStorage.clear();
    navigate('/login');
  };

  const handleRestaurantSelect = (restaurantId, restaurantName) => {
    navigate('/chat', { state: { restaurantId, restaurantName } });
  };

  const handleRestaurantDelete = async (restaurantId) => {
    try {
      const data = await dataService.deleteRestaurant(userId, restaurantId);
      console.log("AAAA", data)
      setRestaurants(data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Grid container spacing={2} direction="column" alignItems="center" justify="center">

          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Welcome, {userData?.name}
            </Typography>
          </Grid>

          {(canUserEditRestaurants(userData)) ? (
            <Grid item xs={12}>
              <Button 
                fullWidth 
                variant='contained' 
                color="secondary"
                onClick={() => navigate('/add-restaurant')}
              >
                Add Restaurant
              </Button>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Button 
              fullWidth 
              variant='contained' 
              color="secondary"
              onClick={() => navigate('/edit-profile', {state: {userId}})}
            >
              Edit Profile
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button 
              fullWidth 
              variant='contained' 
              color="secondary"
              onClick={() => navigate('/modify-preferences', {state: {restrictionType: 'allergy'}})}
            >
              Edit Allergies
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button 
              fullWidth 
              variant='contained' 
              color="secondary"
              onClick={() => navigate('/modify-preferences', {state: {restrictionType: 'preference'}})}
            >
              Edit Preferences
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ width: '100%' }}>
              {restaurants.map((item) => (
                <Paper>
                <Box display="flex" alignItems="center">
                  {canUserEditRestaurants(userData) ? (
                    <IconButton aria-label="delete" onClick={() => handleRestaurantDelete(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  ) : null}
                  <Button
                    variant='contained'
                    key={item.id}
                    onClick={() => handleRestaurantSelect(item.id, item.name)}
                  >
                    {item.name}
                  </Button>
                </Box>
              </Paper>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default DashboardScreen;
