import React from 'react';
import { UserProvider } from './UserContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import CreateAccountScreen from './screens/CreateAccountScreen';
import DashboardScreen from './screens/DashboardScreen';
import ChatScreen from './screens/ChatScreen';
import ModifyPreferencesScreen from './screens/ModifyPreferencesScreen';
import EditProfileScreen from './screens/EditProfileScreen';
import AboutScreen from './screens/AboutScreen';
import AddRestaurantScreen from './screens/AddRestaurantScreen';
import LandingScreen from './screens/LandingScreen';
import HowItWorksScreen from './screens/HowItWorksScreen';
import PricingScreen from './screens/PricingScreen';
import Layout from './NavBarLayout';


const App = () => {
  return (
    <UserProvider>
    <Router>
      <Layout>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/create-account" element={<CreateAccountScreen />} />
        <Route path="/about" element={<AboutScreen />} />
        <Route path="/" element={<LandingScreen />} />
        <Route path="/dashboard" element={<DashboardScreen />} />
        <Route path="/chat" element={<ChatScreen />} />
        <Route path="/modify-preferences" element={<ModifyPreferencesScreen />} />
        <Route path="/edit-profile" element={<EditProfileScreen />} />
        <Route path="/add-restaurant" element={<AddRestaurantScreen />} />
        <Route path="/how-it-works" element={<HowItWorksScreen />} />
        <Route path="/pricing" element={<PricingScreen />} />
      </Routes>
      </Layout>
    </Router>
    </UserProvider>
  );
};

export default App;
